<template>
  <div>
    <div class="search-bar" @mouseenter="OpenSearchBar" @mouseleave="CloseSearchBar">
      <input ref="searchBar" v-model="searchText" type="text" />
      <i class="icon--search" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: ''
    }
  },
  methods: {
    OpenSearchBar() {
      const searchBar = this.$refs.searchBar

      searchBar.style.width = '150px'
      searchBar.style.padding = '0px 5px'
    },
    CloseSearchBar() {
      const searchBar = this.$refs.searchBar

      searchBar.style.width = '0px'
      searchBar.style.padding = '0px'
    }
  },
}
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  align-items: center;

  padding: 10px;
  height: 38px;
  border: 1px solid #ebebeb;
  border-radius: 20px;

  cursor: pointer;

  input {
    width: 0px;
    border: none;
    outline: none;

    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  }

  /* &:hover {
    input {
      width: 150px;
      padding: 0px 5px;
    }
  } */
}
.icon--search {
  width: 16px;
  height: 16px;
  background-image: url('../../assets/dashboard_search.svg');
  pointer-events: none;
}
</style>
