<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue'
export default {
  name: 'App',
  components: { Header }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
ul,
ol {
  list-style: none;
}
</style>
