<template>
  <div class="container">
    <StickyHeader contents="Sticky Block 1" bg="#ec0047" depth="1" />
    <StickyHeader contents="Sticky Block 2" bg="#000" depth="2" />
    <StickyHeader contents="Sticky Block 3" bg="#02343F" depth="3" />
    <StickyHeader contents="Sticky Block 3-2" bg="#0066ff" depth="3" />
    <StickyHeader contents="Sticky Block 2-2" bg="#0066ff" depth="2" />
    <StickyHeader contents="Sticky Block 1-2" bg="#0066ff" depth="1" />
  </div>
</template>

<script>
import StickyHeader from '@/components/css/StickyHeader'
export default {
  name: 'CssSticky',
  components: {
    StickyHeader
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  row-gap: 300px;

  width: 100%;
  height: 4000px;
  padding-top: 80px;

  background-color: #fff;
}
</style>
