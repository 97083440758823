import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './views/Home.vue'
import CssMain from './views/css'

import ScrollSlideIn from './views/css/scroll/SlideIn.vue'
import ScrollSlideOut from './views/css/scroll/SlideOut.vue'

import Search from './views/css/Search.vue'

import EtcCube from './views/css/etc/Cube.vue'
import EtcSticky from './views/css/etc/Sticky.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/css',
    name: 'css-main',
    component: CssMain
  },
  {
    path: '/css/scroll/slidein',
    name: 'scroll-slide-in',
    component: ScrollSlideIn
  },
  {
    path: '/css/scroll/slideout',
    name: 'scroll-slide-out',
    component: ScrollSlideOut
  },
  {
    path: '/css/search',
    name: 'css-search',
    component: Search
  },
  {
    path: '/css/etc/cube',
    name: 'css-cube',
    component: EtcCube
  },
  {
    path: '/css/etc/sticky',
    name: 'css-sticky',
    component: EtcSticky
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
