<template>
  <div class="cube">
    <div class="cube-face cube-face--front">front</div>
    <div class="cube-face cube-face--back">back</div>
    <div class="cube-face cube-face--right">right</div>
    <div class="cube-face cube-face--left">left</div>
    <div class="cube-face cube-face--top">top</div>
    <div class="cube-face cube-face--bottom">bottom</div>
  </div>
</template>
<script>
export default {
  name: 'CubeBox'
}
</script>
<style lang="scss" scoped>
@keyframes rotateCube {
  fromt {
    transform: rotateX(360deg) rotateY(0deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
  }
}

.cube {
  width: 200px;
  height: 200px;

  transform-style: preserve-3d;
  animation: rotateCube 3s infinite linear normal;

  .cube-face {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 3px solid white;
    box-shadow: 0px 0px 40px 5px yellow;

    &--front {
      background: hsla(0, 100%, 50%, 0.5);
      transform: rotateY(0deg) translateZ(100px);
    }
    &--right {
      background: hsla(60, 100%, 50%, 0.5);
      transform: rotateY(90deg) translateZ(100px);
    }
    &--back {
      background: hsla(120, 100%, 50%, 0.5);
      transform: rotateY(180deg) translateZ(100px);
    }
    &--left {
      background: hsla(180, 100%, 50%, 0.5);
      transform: rotateY(-90deg) translateZ(100px);
    }
    &--top {
      background: hsla(240, 100%, 50%, 0.5);
      transform: rotateX(90deg) translateZ(100px);
    }
    &--bottom {
      background: hsla(300, 100%, 50%, 0.5);
      transform: rotateX(-90deg) translateZ(100px);
    }
  }
}
</style>
