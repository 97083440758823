<template>
  <div class="header" :style="{ backgroundColor: bg, top: depth * 80 + 'px' }">
    <span>{{ contents }}</span>
  </div>
</template>

<script>
export default {
  props: ['contents', 'bg', 'depth']
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 10px;

  width: 100%;
  height: 80px;

  span {
    color: white;
  }
}
</style>
