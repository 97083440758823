<template>
  <div class="container">
    <CubeBox />
  </div>
</template>
<script>
import CubeBox from '@/components/css/Cube'
export default {
  name: 'CssCube',
  components: {
    CubeBox
  }
}
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  perspective: 400px;
}
</style>
