<template>
  <div class="search">
    <h2>Only CSS</h2>
    <SearchBar />
    <h2>JavaScript</h2>
    <SearchBarJs />
  </div>
</template>

<script>
import SearchBar from '@/components/css/SearchBar'
import SearchBarJs from '@/components/css/SearchBarJs'
export default {
  name: 'CssSearch',
  components: {
    SearchBar,
    SearchBarJs
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  width: 100%;
  min-height: 100vh;

  h2 {
    margin-top: 50px;
  }
}
</style>
