<template>
  <div class="home">
    <div class="home__router">
      <router-link to="/css">CSS</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RndHome'
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;

  width: 100%;

  &__router {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    justify-content: start;
    gap: 20px;

    width: 100%;
    padding: 50px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 150px;
      padding: 5px;
      border-radius: 10px;

      font-size: 30px;

      transition: all 0.5s linear;
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
      0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
