<template>
  <div class="css">
    <div class="css__category">
      <h2 class="css__title">Scroll</h2>
      <div class="css__router">
        <SlideIn direction="up">
          <router-link to="/css/scroll/slidein"> Slide-In </router-link>
        </SlideIn>
        <router-link to="/css/scroll/slideout"> Slide-Out </router-link>
      </div>
    </div>
    <div class="css__category">
      <h2 class="css__title">Search</h2>
      <div class="css__router">
        <router-link to="/css/search"> Search </router-link>
      </div>
    </div>
    <div class="css__category">
      <h2 class="css__title">Etc.</h2>
      <div class="css__router">
        <router-link to="/css/etc/sticky"> sticky </router-link>
        <router-link to="/css/etc/cube"> cube </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import SlideIn from '@/components/css/ScrollSlideInBox.vue'

export default {
  name: 'CssMain',
  components: {
    SlideIn
  }
}
</script>
<style lang="scss" scoped>
.css {
  display: flex;
  flex-direction: column;

  width: 100%;

  &__category {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    padding: 50px;

    border-bottom: 1px solid #ebebeb;
  }

  &__router {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    justify-content: start;
    gap: 20px;

    width: 100%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 150px;
      padding: 5px;
      border-radius: 10px;

      font-size: 30px;

      transition: all 0.5s linear;
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
        0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
