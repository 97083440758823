<template>
  <div class="slide-in">
    <Box direction="down">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: down</p>
        </div>
      </div>
    </Box>
    <Box direction="up">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: up</p>
        </div>
      </div>
    </Box>
    <Box direction="left">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: left</p>
        </div>
      </div>
    </Box>
    <Box direction="right">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: right</p>
        </div>
      </div>
    </Box>
    <Box direction="down">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: down</p>
        </div>
      </div>
    </Box>
    <Box direction="up">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: up</p>
        </div>
      </div>
    </Box>
    <Box direction="left">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: left</p>
        </div>
      </div>
    </Box>
    <Box direction="right">
      <div class="post">
        <img src="https://cdn.pixabay.com/photo/2018/02/16/21/06/blue-whale-3158626_960_720.png" alt="" />
        <div class="post__contents">
          안녕하세요
          <p>direction: right</p>
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/css/ScrollSlideInBox.vue'

export default {
  name: 'ScrollSlideIn',
  components: {
    Box
  }
}
</script>

<style lang="scss" scoped>
.slide-in {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  padding: 50px;
  width: 100%;

  .post {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 50px;
    padding: 30px;

    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
      0 -6px 16px -6px rgba(0, 0, 0, 0.025);

    img {
      width: 100%;
    }
  }
}
</style>
