<template>
  <div class="header">
    <h2 class="header__title">4grit R&D</h2>
    <router-link to="/">Home</router-link>
    <router-link to="/css">CSS</router-link>
  </div>
</template>

<script>
export default {
  name: 'layoutHeader'
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  column-gap: 25px;
  position: sticky;
  top: 0px;

  width: 100%;
  height: 80px;
  padding-left: 50px;

  background-color: #0066ff;

  z-index: 5;

  &__title {
    font: bold 26px HelveticaNeue;
    color: #ffdfde;
    margin-right: 15px;
  }

  a {
    font: bold 16px HelveticaNeue;
    color: #ffdfde;
    cursor: pointer;
  }
}
</style>
