<template>
  <div class="box" ref="slideInBox">
    <slot />
  </div>
</template>

<script>
export default {
  props: ['direction'],
  data() {
    return {
      observer: null,
      option: {
        root: null,
        threshold: 0.7
      }
    }
  },
  methods: {
    handleIntersect: function (target) {
      if (target.isIntersecting) {
        this.fadeOut()
      }
    },
    fadeOut: function () {
      this.$refs.slideInBox.style.opacity = '0'
      this.$refs.slideInBox.style.transform = this.getTransform()
    },
    getTransform: function () {
      switch (this.direction) {
        case 'up':
          return `translate3d(0, -50px, 0)`;
        case 'down':
          return `translate3d(0, 50px, 0)`;
        case 'left':
          return `translate3d(-50px, 0, 0)`;
        case 'right':
          return `translate3d(50px, 0, 0)`;
        default:
          return;
      }
    }
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0])
    }, this.option)
    this.observer.observe(this.$refs.slideInBox)
  }
}
</script>

<style lang="scss" scoped>
.box {
  opacity: 1;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
}
</style>
